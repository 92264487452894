import React, { useEffect, forwardRef } from 'react';
import gsap from 'gsap';

export function withSplit(Component) {
  const WrappedComponent = forwardRef((props, ref) => {
    const { delay, split = false } = props;
    // const componentRef = useRef(null);
    useEffect(() => {
      const textElement = ref.current;
      if (split) {
        let ctx = gsap.context(() => {
          gsap.fromTo(
            textElement.querySelectorAll('.ch'),
            {
              opacity: 0,
            },
            {
              duration: 1,
              opacity: 1,
              stagger: 0.1,
              ease: 'back.out(2)',
              scrollTrigger: textElement,
              delay: delay,
            }
          );
        });

        return () => {
          ctx?.revert?.();
        };
      }
    }, [delay, split, ref]);

    return <Component {...props} ref={ref} />;
  });
  return WrappedComponent;
}
