import React, { useEffect, forwardRef } from 'react';
import gsap from 'gsap';

export const withFadeIn = (Component) => {
  const WrappedComponent = forwardRef((props, ref) => {
    const { duration = 1, delay = 0 } = props;
    useEffect(() => {
      let ctx = gsap.context(() => {
        gsap.fromTo(
          ref.current,
          { opacity: 0 },
          { opacity: 1, duration, delay }
        );
      });

      return () => {
        ctx?.revert?.();
      };
    }, [duration, delay, ref]);

    return <Component {...props} ref={ref} />;
  });

  return WrappedComponent;
};
