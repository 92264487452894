import React, { useEffect, useState } from 'react';

export function withDelay(Component, delay = 1000) {
  return function DelayedComponent(props) {
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setShouldRender(true);
      }, delay);

      return () => clearTimeout(timeoutId);
    }, []);

    return shouldRender ? <Component {...props} /> : null;
  };
}
