import { useEffect, useState } from 'react';

export const useScreen = () => {
  const [screen, setScreen] = useState(window.innerWidth);
  const resize = () => {
    setScreen(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return screen;
};
