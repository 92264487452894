import React from 'react';

import './Slider.css';

export function Slider({ items = [], reverse = false }) {
  return (
    <>
      <div>
        <div className="slider-container">
          <ul
            className={`slider-list gap-4  md:gap-0 ${
              reverse ? 'reverse' : ''
            }`}
          >
            {items.map((item, index) => (
              <li className="" key={`t-${item}-${index}`}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <div className="relative min-h-[1.75rem] mb-6 xl:mb-16 xl:min-h-[3rem] flex items-center  overflow-hidden w-full">
        <div
          className={` absolute flex animate gap-2 w-[250%] left-0 ${
            reverse ? 'reverse' : ''
          } `}
        >
          <div className="flex flex-1 w-[50%]   justify-around">
            {items.map((item) => (
              <div className="" key={`f-${item}`}>
                {item}
              </div>
            ))}
          </div>
          <div className="flex flex-1  w-[50%]   justify-around">
            {items.map((item) => (
              <div className=" text-primary" key={`r-${item}`}>
                {item}
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </>
  );
}
