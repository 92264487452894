import React from 'react';
import { forwardRef } from 'react';
import { withFadeIn } from '../../hoc/withFadeIn';
import { withSplit } from '../../hoc/withSplit';
import './Typography.modules.css';

export const Typography = forwardRef(
  (
    {
      element: Element = 'div',
      size = 'md',
      className = '',
      children,
      split = false,
      delay = 0,
      ...props
    },
    ref
  ) => {
    // const disabled = !split;

    if (!split)
      return (
        <Element
          className={`typography typography-${size} ${className}`}
          {...props}
          ref={ref}
        >
          {children}
        </Element>
      );
    else {
      let chars = typeof children === 'string' ? children.split('') : children;
      return (
        <Element
          className={`typography typography-${size} ${className}`}
          {...props}
          ref={ref}
        >
          {chars.map((ch, index) => {
            return (
              <span className="ch" key={`${index}-${ch}`}>
                {ch}
              </span>
            );
          })}
        </Element>
      );
    }
  }
);

export const FadedTypography = withFadeIn(Typography);
export const SplittedTypography = withSplit(Typography);
